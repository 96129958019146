import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { ClienteCTA } from './cliente-cta-model';
import { Favorecido } from './favorecido.model';
import { FavorecidoService } from './favorecido.service';
import { Injectable } from "@angular/core";


@Injectable()
export class ClienteService extends FavorecidoService {

    public url: string = "api/clientes";
    pageResult: PageResult = new PageResult();

    /**
     * Listar varios registros
     */
    public listar(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    /**
     * Listar varios registros AutoComplete
     */
    public listarAC(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchAC', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public findById(id: number) {
        return this.get(`${this.url}/${id}`).toPromise().then(res => <Favorecido>res);
    }

    public findByIdClienteCTA(id: number) {
        return this.get(`${this.url}/findByIdClienteCTA/${id}`).toPromise().then(res => <ClienteCTA>res);
    }

    public findByVenda(id: number) {
        return this.get(`${this.url}/findByClienteVenda/${id}`).toPromise().then(res => <ClienteCTA>res);
    }


}


