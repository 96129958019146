import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { ProdutoCTAModel } from '../../../comercial/pedido/produto-cta-model';
import { ServicoService } from '../../../estoque/servico/servico.service';
import { UnidadeService } from '../../../estoque/unidade/unidade.service';
import { KEY_CODE } from '../../constantes/key-codes';
import { QtdeRegistrosDataTable } from '../../constantes/qtde-registros-data-table';
import { TipoStatus } from '../../constantes/tipo-status';
import { SearchParams } from '../../service/generic.service';
import { MessageService } from '../../service/message.service';

@Component({
    selector: 'p-tabView-pesquisa-avancada-servico',
    templateUrl: './pesquisa-avancada-servico.component.html'
})
export class PesquisaAvancadaServicoComponent {
    
    public searchServico : SearchParams = new SearchParams();
    public selectedServico : ProdutoCTAModel = new ProdutoCTAModel();
    public selectedServicoAux : ProdutoCTAModel = new ProdutoCTAModel();
    public pesquisaServicoForm: FormGroup;
    public navigation : boolean = false;
    public quantidadePesquisa : number = 0;
    public selectedDefaultServico = [];
    public index : number;
    public onServico : boolean = false; //utilizado no HTML
    public descricaoServico : String = 'Serviço';
    
    @Input()  lojaId;
    @Input()  favorecidoId;
    @Output() retornoTipoPesquisa = new EventEmitter();
    @Output() retornoServicoSelecionado = new EventEmitter();

    
    constructor(    public fb: FormBuilder,
                    public _servicoService: ServicoService,
                    public _unidadeservice: UnidadeService,
                    public _messages: MessageService
    ) {
        this.buildPesquisaServicoForm();
    }

    buildPesquisaServicoForm(): void {
        this.pesquisaServicoForm = this.fb.group({
            'id':[''],
            'pesquisaServico': [''],
            'aplicacao': [''],
            'unidadeSigla': [''],
            'categoriaDescricao': [''],
            'origemProdutoDescricao': [''],
            'ncmDescricao':[''],
            'outroCodigo':[''],
            'codigoBarra':['']
        });
    }

    loadServicos(event: LazyLoadEvent) {
        //Calcula a página atual
        let page = 0; 
        this.quantidadePesquisa++;

        if (event.first > 0) {
            page = event.first / event.rows;
        }
        
        this.searchServico.page = page;
        this.searchServico.size = QtdeRegistrosDataTable.QTDE;
        this.searchServico.filters["pesquisa"];
        this.searchServico.filters['status'] = TipoStatus.ATIVO;
        this.searchServico.sorting[event.sortField] = event.sortOrder == -1 ? 'desc' : 'asc';
        this._servicoService.listPersonalizadoServico(this.searchServico);

        if(page >= 0){
            //Fica ouvindo o resultado da seleção do Produto Default
            var interval = setInterval(() =>{
                this.selectedDefaultServico = this._servicoService.pageResult.content;
                if(this.selectedDefaultServico.length > 0 ){ 
                    clearInterval(interval);                   
                    // Set default row selection in first row DataTable Produtos
                    this.selectedServico = this.selectedDefaultServico['0'];
                    this.onServico = true;
                    this.index = 0;
                    this.selectedFirstRow();      
                }
            },600); 
        }
    }

    onPesquisaServico(event) {
        /* Event diferente de SHIFT, TAB e SHIFT + TAB */
        if(event.keyCode != KEY_CODE.TECLA_SHIFT){
            if(event.keyCode != KEY_CODE.TECLA_TAB && event.keyCode != KEY_CODE.TECLA_SETA_PARA_BAIXO && event.keyCode != KEY_CODE.TECLA_ENTER){
                if(event.shiftKey != true && event.keyCode != KEY_CODE.TECLA_TAB){
                    this.navigation = false;
                    this.loadServicos(event); 
                }
            }else{
                if(event.keyCode == KEY_CODE.TECLA_TAB){
                    let table:any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO){
                    let table:any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_ENTER){
                    let table:any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    this.onSelect(event);
                    event.preventDefault();
                }
            }
        }
    }

    //EVENT EMITTER
    //selecionar elemento no dataTable e enviar a seleção para a Tela de Pesquisa Simples (input:search)
    onSelect(event):void{
        this.selectedServico = this.selectedServicoAux['0'];
        if(this.selectedServico != null){
            if(this.favorecidoId == null){
                this._messages.warn("Insira o nome do Cliente antes de selecionar o serviço.");
                this.elementFocus(event,"#favorecidoPessoaNomeFantasia > span > input");
            }else{
                this.pesquisaServicoForm.get('pesquisaServico').setValue('');
                this.retornoServicoSelecionado.emit(this.selectedServico);
                this.retornarPesquisaSimples();
            }
        }else{
            console.log("ERRO AO SELECIONAR O SERVIÇO - Descrição do produto vazia");
        }  
    }

    //EVENT EMITTER
    retornarPesquisaSimples(){
        this.retornoTipoPesquisa.emit({pesquisa:false});
    }

    onClickServico(event){
        
        let button;
        let alvo = event.target;
        let buttonSelected;

        this.onServico = true; //mostrar informações completas do produto
        this.selectedServico = this.selectedServicoAux['0'];
        //console.log(alvo);
        switch(alvo.nodeName){
            case 'BUTTON':
                console.log("BUTTON");
                button = alvo.parentElement;
                button.click();

            break;
            case 'I':
                console.log("I");
                button = alvo.parentNode;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
            break;
            case 'DIV':
                console.log("DIV");
                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
            break;
            case 'TD' :
                console.log("TD");
                button = alvo.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
            break;
            case 'SPAN' :
                console.log("SPAN");
                button = alvo.parentNode.parentElement.firstElementChild.lastElementChild;
                if(button.nodeName == 'SPAN') button = alvo.parentNode.parentNode.parentElement.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
            break;
            case 'P-TABLE':
                console.log("P-TABLE");
                if(alvo.firstElementChild.firstElementChild.firstElementChild != null){
                    //Primeiro clique : O evento possui a navegação indicada
                    button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                    
                }else{
                    //Segundo clique em diante : O evento não possui a primeira navegação indicada e sim esta
                    button = alvo.firstElementChild.firstElementChild.nextElementSibling.nextElementSibling.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                }
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                //buttonSelected = document.querySelector(".selected-line");
                //if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
            break;
            default:
                console.log("Default: ",alvo.nodeName);

            if(this.selectedServico.descricao != null) this.descricaoServico = this.selectedServico.descricao;
            
        }
    }

    /**
     * Navegar no dataTable selecionando os elementos | SELECAO-DATA-TABLE-SERVICE.TS Melhorado e Adaptado para esta solução
     *
     */
    onKeydownDiv(event) {
        let buttonSelected;
        let proxButton;
        let prevButton;
        /* KeyCode = seta para baixo */
        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO) {
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }        
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){
                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            } 
        }

        /* KeyCode = seta para a cima */
        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_CIMA) {
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             
            }else{
                if(event.target.parentElement.parentElement){
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }      
        }

        if(event.shiftKey == true && event.keyCode == KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }else{
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";
                }
            }else{
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }     
            return;
        }

        if(event.keyCode == KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){

                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){ 

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             }
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_DIREITA){
            let prox = document.getElementsByClassName('ui-paginator-next');
            prox[1].id = 'prox';
            document.getElementById('prox').click();
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_ESQUERDA){
            let prev = document.getElementsByClassName('ui-paginator-prev');
            prev[1].id = 'prev';
            document.getElementById('prev').click();
        }

        /* KeyCode = Enter - Selecionar produto */
        if(event.keyCode ==  KEY_CODE.TECLA_ENTER){
            this.onSelect(event);
        }
    }

    /**
     * Navegar no dataTable dos Produtos Equivalentes selecionando os elementos | SELECAO-DATA-TABLE-SERVICE.TS Melhorado e Adaptado para esta solução
     *
     */

    selectedFirstRow(){
        let arr = document.getElementsByClassName('btn-transparent');
        arr[0].className = "btn-transparent selected-line";
        arr[0].id = "primeiro-item";
        document.getElementById("primeiro-item").click();
        document.getElementById("inputPesquisaServico").focus();
    }

    elementFocus(event,t){
        let target : any = t;
        document.querySelector(target).focus();
        if(event != null) event.preventDefault();
    } 

}